import React from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./Auth/AuthContext";
import Home from "./Home";
import Contact from "./Contact";
import Login from "./Auth/Login";
import SignUp from "./Auth/SignUp";
import ForgotPassword from "./Auth/ForgotPassword";
import ResetPassword from "./Auth/ResetPassword";
import { RouteGuard, RouteAdminGuard } from "./RouteGuard";
import { NavigationComponent } from "./components/navigation";
import CameraPageWrapper from "./Camera";
import GalleryPage from "./Gallery";

function App() {
  return (
    <>
    <div className="container-fluid min-vh-100 mb-5">
    <AuthProvider>
      <BrowserRouter>
        <NavigationComponent />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route
              path="/camera"
              element={<RouteGuard><CameraPageWrapper /></RouteGuard>}
            />

            <Route
              path="/gallery"
              element={<RouteGuard><GalleryPage /></RouteGuard>}
            />
          </Routes>
      </BrowserRouter>
    </AuthProvider>
    </div>
    <div className="container d-none d-md-block">
      <p className="mt-5 mb-3 text-muted text-center fixed-bottom">DOTTOD © 2020-{new Date().getFullYear()}</p>
    </div>
    </>
  );
}

export default App;
