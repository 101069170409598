import { useState, useContext } from "react"
import { AuthContext } from "./AuthContext"
import { Link, Navigate, useNavigate } from "react-router-dom";
import gen8 from '../assets/images/gen8.jpg';

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { user, signIn } = useContext(AuthContext)
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault()
    setError("")
    try {
      await signIn(email, password)
    } catch (err) {
      console.log(err.code, err.code.toString())
      if (err.code.toString() == "UserNotConfirmedException") {
        navigate("/signup", {state: {email: email}});
      }
      setError(err.message)
    } finally {
      setLoading(false);
    }
  }

  // If the user is logged in, don't show the login form
  if (user) {
    return <Navigate to="/camera" />
  }

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-around align-items-center">
        <div className="p-4" style={{minWidth: "340px"}}>
          <div className="mb-4">
            <h1 className="h3 mb-3 font-weight-normal">Login</h1>
          </div>

          <div className="form-label-group mb-3">
            <label for="inputEmail">Email address</label>
            <input type="email" className="form-control" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>

          <div className="form-label-group mb-3">
            <label for="inputPassword">Password</label>
            <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <p className="small">
            Forgot your password? Click <Link to={"/forgot-password"}>here</Link>.
          </p>
          { !loading && <button className="btn btn-dark btn-block" onClick={handleSubmit}>Sign in</button> }
          { loading && <button className="btn btn-dark btn-block" disabled={true}>
            <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
            Signing in ... 
          </button> }
          {error && <p>{error}</p>}
        </div>
        <div className="p-4 d-none d-md-block">
          <img src={gen8} className="img-fluid" style={{maxWidth: "500px"}} />
        </div>
      </div>
    </div>
  )
}