import { useState, useEffect } from "react"
import { signUp, resendConfirmationCode, confirmSignUp } from "./auth"
import VerificationInput from "react-verification-input";
import gen8 from '../assets/images/gen8.jpg';
import { Link, useLocation } from "react-router-dom";

export default function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPwd, setRepeatPwd] = useState("");
  const [error, setError] = useState("");
  const [code, setCode] = useState("");
  const [disabledCode, setDisabledCode] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successCode, setSuccessCode] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    if(location.state === null) return;
    let { email } = location.state;
    if(email) {
      setEmail(email);
      setSuccess(true);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    setError("")

    try {
      await signUp(email, password)
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  const handleConfirm = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await confirmSignUp(email, code);
      setSuccessCode(true);
    } catch (err) {
      setError(err.message);
    }
  }

  const handleResendConfirmationCode = async () => {
    try {
      await resendConfirmationCode(email);
      setDisabledCode(true);
      setTimeout(() => {
        setDisabledCode(false);
      }, 60000);

    } catch (err) {
      setError(err.message);
    }
  }

  if (successCode) {
    return (
      <div className="container mt-5">
        <div className="d-flex justify-content-around align-items-center">
          <div className="p-4" style={{minWidth: "340px"}}>
            <div className="mb-4">
              <h1 className="h3 mb-3 font-weight-normal">Confirm Email Address</h1>
              <p>Confirmation successful! <br/> You can now login with your credentials 🙂</p>
              <Link to="/login" role="button" className="btn btn-dark">Go to Login page</Link>
            </div>
          </div>
          <div className="p-4">
            <img src={gen8} className="img-fluid" style={{maxWidth: "500px"}} />
          </div>
        </div>
      </div>
    )
  }

  if (success) {
    return (
      <div className="container mt-5">
        <div className="d-flex justify-content-around align-items-center">
          <div className="p-4" style={{minWidth: "340px"}}>
            <div className="mb-4">
              <h1 className="h3 mb-3 font-weight-normal">Confirm Email Address</h1>
              <p>Check your email address, you should have received a verification code.</p>
            </div>

            <div className="form-label-group">
              <label>Confirmation code</label>
              <VerificationInput 
                className="form-control" 
                value={code} onChange={setCode} 
                validChars="0-9" 
                placeholder={''} 
                classNames={{
                  character: "multi-character",
                }}
              />
            </div>
            <p>
            { disabledCode && <small>confirmation code successfully re-sent!</small> }
            { !disabledCode && <a role="button" onClick={() => handleResendConfirmationCode()}><small>resend confirmation code</small></a> }
            </p>

            { !loading && 
              <button 
                className="btn btn-dark btn-block" 
                onClick={handleConfirm}
                disabled={code.length < 6}
              >Confirm Email</button> }
            { loading && <button className="btn btn-dark btn-block" disabled={true}>
              <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
              Checking ... 
            </button> }
            {error && <p>{error}</p>}
          </div>
          <div className="p-4">
            <img src={gen8} className="img-fluid" style={{maxWidth: "500px"}} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-around align-items-center">
        <div className="p-4" style={{minWidth: "340px"}}>
          <div className="mb-4">
            <h1 className="h3 mb-3 font-weight-normal">SignUp</h1>
          </div>

          <div className="form-label-group mb-3">
            <label for="inputEmail">Email address</label>
            <input type="email" className="form-control" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>

          <div className="form-label-group mb-3">
            <label for="inputPassword">Password</label>
            <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>

          <div className="form-label-group mb-3">
            <label for="inputPassword">Repeat Password</label>
            <input type="password" className="form-control" placeholder="Password" value={repeatPwd} onChange={(e) => setRepeatPwd(e.target.value)} />
          </div>

          { !loading && 
            <button 
              className="btn btn-dark btn-block" 
              onClick={handleSubmit}
              disabled={password.length < 6 || password !== repeatPwd || !emailRegex.test(email)}
            >Sign up</button> }
          { loading && <button className="btn btn-dark btn-block" disabled={true}>
            <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
            Signing up ... 
          </button> }
          {error && <p>{error}</p>}
        </div>
        <div className="p-4 d-none d-md-block">
          <img src={gen8} className="img-fluid" style={{maxWidth: "500px"}} />
        </div>
      </div>
    </div>
  )
}