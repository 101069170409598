import { useContext } from "react"
import { Navigate } from "react-router-dom"
import { AuthContext } from "./Auth/AuthContext"

export function RouteGuard({ children }) {
  const { user, isLoading } = useContext(AuthContext)
  if (isLoading) {
    return <></>
  }
  if (!user) {
    return <Navigate to="/login" />
  }
  return children
}
export function RouteAdminGuard({ children }) {
  const { user, isLoading } = useContext(AuthContext)
  if (isLoading) {
    return <></>
  }
  if (!user || !user["custom:is_admin"]) {
    return <Navigate to="/" />
  }
  return children
}