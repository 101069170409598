import { useState } from "react"
import { confirmPassword } from "./auth"
import VerificationInput from "react-verification-input";
import gen8 from '../assets/images/gen8.jpg';
import { Link } from "react-router-dom";

export default function ResetPassword() {
  const [email, setEmail] = useState("")
  const [confirmationCode, setConfirmationCode] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault()
    setError("")
    try {
      await confirmPassword(email, confirmationCode, newPassword)
      setSuccess(true)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false);
    }
  }

  if (success) {
    return (
      <div className="container mt-5">
        <div className="d-flex justify-content-around align-items-center">
          <div className="p-4" style={{minWidth: "340px"}}>
            <div className="mb-4">
              <h1 className="h3 mb-3 font-weight-normal">Reset Password</h1>
              <p>Your password has been reset successfully!</p>
              <Link to="/login" role="button" className="btn btn-dark">Go to Login page</Link>
            </div>
    
    
          </div>
          <div className="p-4 d-none d-md-block">
            <img src={gen8} className="img-fluid" style={{maxWidth: "500px"}} />
          </div>
        </div>
      </div>
    )
  }


  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-around align-items-center">
        <div className="p-4" style={{minWidth: "340px"}}>
          <div className="mb-4">
            <h1 className="h3 mb-3 font-weight-normal">Reset Password</h1>
          </div>

          <div className="form-label-group mb-3">
            <label for="inputEmail">Email address</label>
            <input type="email" className="form-control" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>

          <div className="form-label-group mb-3">
            <label for="inputPassword">New Password</label>
            <input type="password" className="form-control" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
          </div>

          <div className="form-label-group mb-3">
            <label>Confirmation code</label>
            <VerificationInput 
              className="form-control" 
              value={confirmationCode} onChange={setConfirmationCode} 
              validChars="0-9" 
              placeholder={''} 
              classNames={{
                character: "multi-character",
              }}
            />
          </div>

          { !loading && <button className="btn btn-dark btn-block" onClick={handleSubmit} disabled={confirmationCode.length < 6 || !emailRegex.test(email)} >Reset Password</button> }
          { loading && <button className="btn btn-dark btn-block" disabled={true}>
            <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
            Resetting Password ... 
          </button> }
          {error && <p>{error}</p>}
        </div>
        <div className="p-4 d-none d-md-block">
          <img src={gen8} className="img-fluid" style={{maxWidth: "500px"}} />
        </div>
      </div>
    </div>
  )
}