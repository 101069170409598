import React, {useEffect, useState} from "react";
import ufo from '../assets/images/ufo.gif'

export function Loader(props) {
    const [progressBarValue, setProgressBarValue] = useState(props.progress);

    useEffect(() => {
        const intervalId = setInterval(() => {
          setProgressBarValue((prev) => {
            if (prev >= 100) {
              clearInterval(intervalId);
              return 100;
            } else {
              return prev + 10;
            }
          });
        }, 2000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="loader-wrapper">
            <div className="loader text-center">
                <img src={ufo} style={{width: "50px"}} />
                <div className="progress mt-2">
                    <div className="progress-bar" role="progressbar" style={{width: (progressBarValue || 20) + "%"}}>
                        {(progressBarValue || 20)}%
                    </div>
                </div>
                <small className="font-monospace">please wait</small>
            </div>
        </div>
    );
}