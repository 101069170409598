import { useContext, useEffect, useState, useRef, useCallback } from "react"
import { AuthContext } from "./Auth/AuthContext"
import Webcam from "react-webcam";
import {PRESETS, createBaseImage, createCameraEntries, deleteBaseImages, deleteCameraEntries, listBaseImages, listCameraEntries, preFlightBaseImage, uploadS3BaseImage, uuidv4 } from "./services/base_image.service";
import { Buffer } from "buffer";
import { Loader } from "./components/loader";
import { dateStringToHuman } from "./helpers/dateHelpers";

const MIN_API_LENGTH = 40;

export default function CameraPageWrapper() {
  const { user } = useContext(AuthContext);

  if(user["custom:openai_key"] !== undefined && user["custom:openai_key"].length > 40) return <CameraPage />
  return (
    <div className="container mt-5 vh-100">
      <div className="d-flex justify-content-center align-items-center text-center" style={{height: 'calc(100% - 400px)'}}>
        <div style={{maxWidth: "500px"}}>
          <h1>Welcome!</h1>
            <p>Before starting you have to provide a OpenAI API key. The key will be used to perform actions on behalf of your OpenAI Account.<br />
              Follow the instructions <a href="https://www.maisieai.com/help/how-to-get-an-openai-api-key-for-chatgpt" target="_blank">here</a> if you dont have a key yet.
            </p>
          <CameraAPIKey floating={false}/>
        </div>
      </div>
    </div>
  )
}

export function CameraPage() {
  const { user } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [baseImages, setBaseImages] = useState([]);
  const [selectedBaseImage, setSelectedBaseImage] = useState(null);
  const [settings, setSettings] = useState("");
  const [preset, setPreset] = useState(PRESETS[0].value);
  const [size, setSize] = useState("1024x1024");

  const [cameraEntries, setCameraEntries] = useState([]);
  const [filteredCameraEntries, setFilteredCameraEntries] = useState([]);
  const [selectedCameraEntry, setSelectedCameraEntry] = useState(null);

  const [blur, setBlur] = useState(true);

  useEffect(() => {
    if (!user) return <Navigate to="/login" />
    setLoading(true);
    Promise.all([listBaseImages(user), listCameraEntries(user)]).then((responses) => {
      let entries = sortEntries(responses[0].data.base_image_entries);
      setBaseImages(entries);
      entries = sortEntries(responses[1].data.camera_entries);
      setCameraEntries(entries);
    }).catch(err => {
      console.log(err);
      setError(err.message);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if(selectedBaseImage === null) return;
    let entries = cameraEntries.filter(ce => ce.base_image_id === selectedBaseImage.pk);
    setFilteredCameraEntries(entries);
  }, [selectedBaseImage]);  

  const addBaseImage = (baseImage) => {
    let entries = sortEntries([baseImage, ...baseImages]);
    setBaseImages(entries);
    setSelectedBaseImage(baseImage);
  }

  const sortEntries = (entries) => {
    return entries.sort(function(a,b){
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  }

  const generateImage = () => {
    if(selectedBaseImage === null || settings.length < 10) return;
    setLoading(true);
    createCameraEntries(user, selectedBaseImage.pk, settings, size).then(res => {
      let new_entry = res.data;
      let entries = [new_entry, ...cameraEntries];
      setCameraEntries(entries);
      let filtered_entries = [new_entry, ...filteredCameraEntries]
      setFilteredCameraEntries(filtered_entries);
      setSelectedCameraEntry(new_entry);
    }).catch(err => {
      console.log(err);
      setError(err.message);
    }).finally(() => {
      setLoading(false);
    });
  }

  const deleteImage = (pk) => {
    return new Promise((resolve, reject) => {
      deleteCameraEntries(user, pk).then(res => {
        let FcameraEntries = cameraEntries.filter(ce => ce.pk !== pk);
        let FfilteredCameraEntries = filteredCameraEntries.filter(ce => ce.pk !== pk);
        setCameraEntries(FcameraEntries);
        setFilteredCameraEntries(FfilteredCameraEntries);
        setSelectedCameraEntry(null);
        resolve()
      }).catch(err => reject(err));
    });
  }

  const deleteSnapshot = () => {
    if (!confirm('Are you sure you want to delete this snapshot?')) return;
    setLoading(true);
    deleteBaseImages(user, selectedBaseImage.pk).then(res => {
      let FbaseImages = baseImages.filter(bi => bi.pk !== selectedBaseImage.pk);
      setBaseImages(FbaseImages);
      setSelectedBaseImage(null);
    }).catch(err => setError(err))
    .finally(() => setLoading(false));
  }

  if (loading) return <Loader progress={10}/>

  return (
    <div>
      {user && (
        <div className="container-fluid mt-lg-3">
          <CameraAPIKey floating={true} />
          <CameraEntryOverlay selectedCameraEntry={selectedCameraEntry} setSelectedCameraEntry={setSelectedCameraEntry} deleteImage={deleteImage} />
          <div className="row">
            <div className="col-12">
                <div className="d-none d-md-block">
                  <h4>Reality Snapshots</h4>
                  <p className="d-none d-md-block">Take a snapshot to start using the AI Camera. <br/> The snapshot will be used as a AI interpretation of the real word.</p>
                  <span className="mb-4"><CaptureImage addBaseImage={addBaseImage} /></span>
                </div>
                <div className="d-flex justify-content-between align-item-center d-block d-md-none mt-2">
                  <div><h4>Reality Snapshots</h4></div>
                  <div>
                    <CaptureImage className="btn-sm" addBaseImage={addBaseImage} />
                  </div>
                </div>
                <div className="d-flex flex-nowrap overflow-scroll justify-content-start mt-1 mt-md-3">
                {
                  baseImages.map((bi, idx, arr) => {
                    let cn = "p-2";
                    if(idx === 0) cn = "pe-2 py-2";
                    if(idx === arr.length-1 && arr.length > 1) cn = "ps-2 py-2";
                    return (
                      <div className={cn} key={`bi-${idx}`} onClick={() => setSelectedBaseImage(bi)}>
                        <div className={`${(selectedBaseImage && bi.pk === selectedBaseImage.pk) && "border-bot"}`}>
                          <div className="video-gradient mb-1">
                              <span className="inner-img-tag">
                              { (selectedBaseImage && bi.pk === selectedBaseImage.pk) && <span className="badge bg-dark font-monospace">selected</span> }
                              { (idx === 0 && (!selectedBaseImage || bi.pk !== selectedBaseImage.pk)) && <span className="badge bg-dark font-monospace">latest</span> }
                              </span> 
                            <img 
                              className={`img-fluid img-blur ${!blur && " no-blur"}`} 
                              style={{minWidth: "120px", maxWidth:"120px", height:"90px"}} 
                              src={process.env.REACT_APP_BUCKET_URL + '/' + bi.image}
                            />
                          </div>
                          <div className="d-flex justify-content-between align-item-center mb-1">
                            <small className="text-muted">{dateStringToHuman(bi.createdAt)}</small>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="col-12 mt-2">
              { selectedBaseImage === null && <h5 className="text-center">
                Select a snapshot to begin
              </h5>}
              { 
                selectedBaseImage !== null && (
                  <div className="row py-4">
                    <div className="col-4 d-none d-md-block">
                      <h5>Selected Snapshot</h5>
                      <div className="video-gradient">
                        <img className={`img-fluid img-blur ${!blur && " no-blur"}`} src={process.env.REACT_APP_BUCKET_URL + '/' + selectedBaseImage.image} />
                      </div>
                      <small className="text-muted">{dateStringToHuman(selectedBaseImage.createdAt)}</small>
                    </div>
                    <div className="col-12 col-md-8">
                      <div className="d-flex justify-content-between align-item-center">
                        <div><h5>Camera Settings</h5></div>
                        <div className="input-group input-group-sm mb-3" style={{maxWidth: "50%"}}>
                          <select className="form-select" value={preset} onChange={(e) => setPreset(e.target.value)}>
                            {
                              PRESETS.map((ps, id) => <option key={`preset-${id}`} value={ps.value}>{ps.text}</option>)
                            }
                          </select>
                          <span className="btn btn-dark" onClick={() => setSettings(preset)}>Set</span>
                        </div>
                      </div>
                      <textarea 
                        style={{width: "100%"}} 
                        rows={4} value={settings}
                        className="form-control" 
                        onChange={(e) => setSettings(e.target.value)}>
                      </textarea>
                      <div className="d-flex justify-content-between">
                        <div className="input-group input-group-sm mt-3">
                          <button className="btn btn-dark btn-sm" onClick={() => deleteSnapshot()}>Delete Snapshot</button>
                        </div>
                        <div className="input-group input-group-sm mt-3">
                          <select className="form-select" value={size} onChange={(e) => setSize(e.target.value)}>
                            <option value="1024x1024">1024x1024</option>
                            <option value="1792x1024">1792x1024</option>
                            <option value="1024x1792">1024x1792</option>
                          </select>
                          <button className="btn btn-dark" disabled={settings.length < 10} onClick={() => generateImage()}>Generate Image</button>
                        </div>
                      </div>
                      {error && <p>{error}</p>}
                    </div>
                    <div className="col-12 mt-2">
                      <h5>AI Generated Images</h5>
                      <div className="d-flex justify-content-start flex-nowrap overflow-scroll">
                        {
                          filteredCameraEntries.map((ce, idx, arr) => {
                            let cn = "p-2";
                            if(idx === 0) cn = "pe-2 py-2";
                            if(idx === arr.length-1 && arr.length > 1) cn = "ps-2 py-2";
                            return (
                              <div className={cn} onClick={() => setSelectedCameraEntry(ce)}>
                                <div className="position-relative">
                                  <img src={process.env.REACT_APP_BUCKET_URL + '/' + ce.output_img} style={{minWidth: "120px", maxWidth:"120px"}} />
                                  <span className="inner-img-tag">
                                  { (idx === 0) && <span className="badge bg-dark font-monospace">latest</span> }
                                  </span>
                                </div>
                                <div className="d-flex justify-content-between align-item-center mt-1">
                                  <small className="text-muted">{dateStringToHuman(ce.createdAt)}</small>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-end">
              {
                user["custom:is_admin"] && (
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" checked={blur} onChange={() => setBlur(!blur)} />
                    <label className="form-check-label">blur</label>
                  </div>
                )
              }
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export function CameraAPIKey(props) {
  const { user, updateAPIKey } = useContext(AuthContext);
  const [apiKey, setApiKey] = useState(user["custom:openai_key"] !== undefined ? user["custom:openai_key"] : "");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAPIUpdate = async (e) => {
    if (apiKey.length === 0) {
      setError("Insert a valid key");
      return;
    }
    setLoading(true);
    setError("")
    try {
      await updateAPIKey(apiKey);
      setOpen(false);
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
    <div className={"modal fade show bg-caki " + (open ? " d-block": "")}tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">OpenAI APIs Key</h5>
            <button type="button" className="btn-close" disabled={user["custom:openai_key"] === undefined || user["custom:openai_key"].length < MIN_API_LENGTH} onClick={() => setOpen(false)}></button>
          </div>
          <div className="modal-body">
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">🔑</span>
              <input type="text" className="form-control" placeholder="OpenAI API Key" value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
            </div>
            {error && <p>{error}</p>}
            {!loading && <button type="button" className="btn btn-dark" disabled={apiKey.length < MIN_API_LENGTH } onClick={handleAPIUpdate}>Save Key</button> }
            { loading && <button className="btn btn-dark" disabled={true}>
              <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
              Storing Key ... 
            </button> }
          </div>
        </div>
      </div>
    </div>
    <span className={`btn btn-sm btn-dark ${props.floating && "sticky-btn"}`} style={{zIndex:"99"}} onClick={() => setOpen(true)}>Set OpenAI Key</span>
    </>
  )
}

function CaptureImage(props) {
  const { user } = useContext(AuthContext);
  const webcamRef = useRef(null);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);

  const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: facingMode,
  };

  const capture = useCallback(() => {
    setLoading(true);
    const image = webcamRef.current.getScreenshot();
    const type = image.split(';')[0].split('/')[1]
    const buffer = Buffer.from(image.replace(/^data:image\/\w+;base64,/, ""),'base64');
    const key = user.sub + '/' + uuidv4() + '.' + type;

    preFlightBaseImage(user, key, type).then(res => {
      const signed_url = res.data.url;
      setProgress(30);
      return uploadS3BaseImage(signed_url, buffer).then(res => {
        setProgress(60);
        return createBaseImage(user, key).then(res => {
          setProgress(100);
          props.addBaseImage(res.data);
        })
      })
    })
    .catch(err => setError(err.message))
    .finally(() => {
      setLoading(false);
      setProgress(0);
      setError("");
      setOpen(false);
    })

  }, [webcamRef]);

  const handleSwitch = useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  return (
    <>
      <span className="btn btn-sm btn-dark" onClick={() => setOpen(true)}>Take Snapshot</span>
      <div className={"modal modal-lg fade bg-caki show " + (open ? " d-block": "")} tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-body">
            { loading && <Loader progress={progress} /> }
              <div className="video-gradient">
                { open && <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  minScreenshotWidth={600}
                  minScreenshotHeight={400}
                /> 
                }
              </div>
              {error && <p>{error}</p>}
            </div>
            <div className="modal-footer border-0 justify-content-between">
              {!loading && <button type="button" className="btn btn-outline-dark mx-1" onClick={handleSwitch}>Switch</button>}
              {!loading && <button type="button" className="btn btn-dark mx-1" onClick={capture}>Capture Snapshot</button> }
              {!loading && <button type="button" className="btn btn-outline-dark mx-1" onClick={() => setOpen(false)}>Close</button> }
              { loading && <button className="btn btn-dark" disabled={true}>
                <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
              </button> }
              { loading && <small className="text-muted mx-2">Please be careful, it might take a while</small> }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function CameraEntryOverlay(props) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(props.selectedCameraEntry === null) return;
    setLoading(false);
    setError("");
    setOpen(true);
  }, [props.selectedCameraEntry]);

  const closeOverlay = () => {
    props.setSelectedCameraEntry(null);
    setOpen(false);
  }

  const deleteImage = () => {
    if (!confirm('Are you sure you want to delete the image?')) return;
    if(props.selectedCameraEntry === null) return;
    setLoading(true);
    props.deleteImage(props.selectedCameraEntry.pk)
    .then(() => setOpen(false))
    .catch(err => setError(error))
    .finally(() => setLoading(false));
  }

  if(props.selectedCameraEntry === null) return;

  return (
    <div className={"modal modal-lg fade bg-caki show " + (open ? " d-block": "")} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0">
          <div className="modal-body">
            <img src={process.env.REACT_APP_BUCKET_URL + '/' + props.selectedCameraEntry.output_img} className="img-fluid" />
            <p className="mt-2 mb-1"><b>Settings: </b>{props.selectedCameraEntry.settings}</p>
            <p className="mb-2"><b>Date: </b>{dateStringToHuman(props.selectedCameraEntry.createdAt)}</p>
            <div className="d-flex justify-content-between">
              <span className="btn btn-dark" onClick={() => closeOverlay()}>Close</span>
              <span>{error && <p>{error}</p>}</span>
              { !loading  && <span className="btn btn-dark" onClick={() => deleteImage()}>Delete Image</span> }
              { loading && <button className="btn btn-dark" disabled={true}>
                <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
              </button> }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}