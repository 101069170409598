import React, {useContext, useState} from "react";
import {Link, LinkProps, useMatch, useResolvedPath} from "react-router-dom";
import { AuthContext } from "../Auth/AuthContext";

function NavLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <div>
            <Link className={"menu-link" + (!match ? "" : " menu-link-active")}
                  to={to}
                  {...props}
            >
                { (!match ? "" : "{ ") }
                {children}
                { (!match ? "" : " }") }
            </Link>
        </div>
    );
}

export function NavigationComponent() {
    const [DFlip, setDFlip] = useState(true);
    const { user, signOut } = useContext(AuthContext);

    return (
        <div className="container-fluid">
            <header className="d-flex flex-wrap justify-content-sm-evenly justify-content-md-between flex-sm-row flex-column align-items-center py-md-3 pt-2">
                <a href="/" className="text-dark text-decoration-none logo-margin">
                    <div
                        className="d-flex justify-content-center"
                        onMouseEnter={() => setDFlip(false)}
                        onMouseLeave={() => setDFlip(true)}
                    >
                        <h1 style={{display: "block"}}>DOT</h1>
                        <h1 style={{display: "block"}} className={(DFlip ? "text-h-flip" : "")}>DOT</h1>
                    </div>
                </a>
                <h3 className="text-center mb-0">
                    <span className="d-none d-md-block">AI Camera</span>
                    <p className="mb-0" style={{fontSize: ".475em"}}>MDEF - Design With Extended Intelligence 2023/24</p>
                </h3>
                <div className="d-flex flex-wrap justify-content-sm-evenly justify-content-between mt-sm-1 mt-3">
                    <span className="px-3 mb-3"><NavLink to={"/"}>home</NavLink></span>
                    { user && <span className="px-3 mb-3"><NavLink to={"/camera"}>camera</NavLink></span> }
                    { (user && user["custom:is_admin"]) && <NavLink to={"/gallery"}>gallery</NavLink> }
                    { !user && <span className="px-3 mb-3"><NavLink to={"/login"}>login</NavLink></span> }
                    { !user && <span className="px-3 mb-3"><NavLink to={"/signup"}>signup</NavLink></span> }
                    { user && <span className="px-3 mb-3" onClick={signOut}>logout</span> }
                </div>
            </header>
        </div>
    );
}