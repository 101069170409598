import { useContext } from "react";
import { AuthContext } from "./Auth/AuthContext";
import gen9 from './assets/images/gen9.jpg';
import {Link} from "react-router-dom";

function Home() {
  const { user, logout } = useContext(AuthContext);

  return (
    <div className="container">
      <div className="row mt-lg-5">
        <div className="col-12 col-lg-7">
          <div className="p-4">
            <div className="mb-4">
              <h1 className="h3 mb-3 font-weight-normal">Welcome!</h1>
              <p>
              This web application introduces a novel method for photography that replaces the conventional camera shutter with an artificial intelligence capable of interpreting the view from your phone's lens and transforming it into what we have termed a "reality snapshot." Users of this innovative AI camera can subsequently produce traditional images from these snapshots, with the ability to refine the results by adjusting the camera settings.
              The focus of this endeavor is to explore camera settings: traditionally, these were presented as dials and parameters. In the realm of AI photography, we are still in the process of defining what these settings might entail. 
              </p>
              <p>The application requires an OpenAI APIs key. Follow <a href="https://help.openai.com/en/articles/4936850-where-do-i-find-my-openai-api-key" target="_blank">this guide</a> to get one.</p>
              { !user && <Link to={"/login"} className="btn btn-dark me-2">Login</Link> }
              { !user && <Link to={"/signup"} className="btn btn-dark">Register</Link> }
              { user && <Link to={"/camera"} className="btn btn-dark">Open the camera</Link> }
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5">
         <img src={gen9} className="img-fluid"/>
        </div>
      </div>
    </div>
  );
}

export default Home;