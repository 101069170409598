import { useContext, useEffect, useState } from "react"
import { AuthContext } from "./Auth/AuthContext";
import { dateStringToHuman } from "./helpers/dateHelpers";
import { listAdminCameraEntries, listCameraEntriesGallery, listUsers } from "./services/base_image.service";
import { Loader } from "./components/loader";

export default function GalleryPage() {
    const { user } = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const [baseImages, setBaseImages] = useState([]);

    const [galleryImages, setGalleryImages] = useState([]);
    const [gallerySelectedImage, setGallerySelectedImage] = useState(null);

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        if(!user["custom:is_admin"]) {
            setSelectedUser(user);
            return;
        }
        listUsers(user).then(res => {
            let users = res.data.users;
            users = users.sort((a,b) => new Date(a.UserCreateDate) - new Date(b.UserCreateDate));
            setUsers(users);
            if(users.length > 0) {
                setSelectedUser(users[0]);
            }
        }).catch(err => setError(err))
        .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if(!selectedUser) return;
        setLoading(true);
        listCameraEntriesGallery(user, selectedUser.sub).then(res => {
            let base_images = res.data.base_images;
            base_images = base_images.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
            setBaseImages(base_images);
        }).catch(err => setError(err))
        .finally(() => setLoading(false));
    }, [selectedUser])

    const openGallery = (images, selectedImage) => {
        setGalleryImages(images);
        setGallerySelectedImage(selectedImage);
    }

    if (loading) return <Loader progress={10}/>
    if (selectedUser === null) return <h1>no creators found</h1>

    return (
        <>
        { baseImages && <GalleryOverlay images={galleryImages} selectedImage={gallerySelectedImage} setSelectedImage={setGallerySelectedImage} /> }
        <div className="container mt-3">
            <div className="row">
                {
                    user["custom:is_admin"] ? (
                        <>
                        <div className="col-12 col-lg-2 mb-4">
                            <GalleryUserSelector users={users} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
                        </div>
                        <div className="col-12 col-lg-10">
                            <GalleryDisplay baseImages={baseImages} openGallery={openGallery} />
                        </div>
                        </>
                    ) : (
                        <div className="col-12">
                            <GalleryDisplay baseImages={baseImages} openGallery={openGallery} />
                        </div>                  
                    )
                }

            </div>
        </div>
        </>
    )
};

function GalleryDisplay(props) {
    return (
        <>
        { props.baseImages.length === 0 && <h5>There are no entries for the selected creator</h5> }
        {
            props.baseImages.map((bi, idx, arr) => {
                return (
                    <div key={bi.pk} className="mb-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <span><h5>Snapshot #{arr.length - idx}</h5></span>
                            <span><h5>{ dateStringToHuman(bi.createdAt)}</h5></span>
                        </div>
                        <p>{bi.text}</p>
                        <div className="row">
                            {
                                bi.camera_entries && bi.camera_entries.map(ce => {
                                    return (
                                        <div key={ce.pk} className="col-4 col-md-3 col-lg-2 mb-3" onClick={() => props.openGallery(bi.camera_entries, ce)}>
                                            <img src={process.env.REACT_APP_BUCKET_URL + '/' + ce.output_img} className="img-fluid" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            })
        }
        </>
    )
}

function GalleryUserSelector(props) {
    return (
        <>
        <div className="d-flex justify-content-between align-items-center mb-1">
            <span><h5>Creators</h5></span>
            <span className=""><h5>#{props.users.length}</h5></span>
        </div>
        <ul className="list-group small">
            {
                props.users.map(u => {
                    return (
                        <li onClick={() => props.setSelectedUser(u)} className={`list-group-item ${props.selectedUser.Username === u.Username && " active"}`} >{u.email}</li>
                    )
                })
            }
        </ul>
        </>
    )
}

function GalleryOverlay(props) {
    const [showOriginal, setShowOriginal] = useState(false);
    const [open, setOpen] = useState(false);
    const [idx, setIdx] = useState(0);
  
    useEffect(() => {
      if(!props.images || !props.selectedImage) return;

      let i = props.images.findIndex(img => img.pk === props.selectedImage.pk);
      
      setIdx(i);
      setOpen(true);
    }, [props.selectedImage]);
  
    const closeOverlay = () => {
      props.setSelectedImage(null);
      setOpen(false);
    }

    const nextImage = (direction) => {
        let newIdx = idx + direction;
        if(newIdx < 0) newIdx = images.length - 1;
        setIdx(newIdx);
    }
  
    if(props.selectedImage === null) return;
  
    return (
      <div className={"modal modal-xl fade bg-caki show " + (open ? " d-block": "")} tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <img src={process.env.REACT_APP_BUCKET_URL + '/' + (showOriginal ? props.images[idx % props.images.length].input_img : props.images[idx % props.images.length].output_img)} className="img-fluid" />
                        <div className="form-check form-switch mt-2">
                            <input className="form-check-input" type="checkbox" checked={showOriginal} onChange={() => setShowOriginal(!showOriginal)} />
                            <label className="form-check-label">toggle original photo</label>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <h5>Settings:</h5>
                        <p>{props.images[idx % props.images.length].settings}</p>
                        <h5>Prompt:</h5>
                        <p>{props.images[idx % props.images.length].prompt}</p>
                        <h5>Revised Prompt:</h5>
                        <p>{props.images[idx % props.images.length].revised_prompt}</p>
                        
                    </div>
                    <div className="col-12">
                        <div className="d-flex justify-content-between mt-2">
                            { props.images.length > 1 && <span className="btn btn-outline-dark" onClick={() => nextImage(-1)}>prev</span> }
                            <span className="btn btn-dark" onClick={() => closeOverlay()}>Close</span>
                            { props.images.length > 1 && <span className="btn btn-outline-dark" onClick={() => nextImage(1)}>next</span> }
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
}