import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const fetchBaseImages = () => {
    return axios.get(API_URL + '/base_image');
}

export const preFlightBaseImage = (user, key, type) => {
    return axios.get(API_URL + '/pre_flight_base_image?file_name=' + key + '&file_type=image/' + type, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': user.token
        }
    });
}

export const uploadS3BaseImage = (signed_url, buffer) => {
    return axios.put(signed_url, buffer, {
        headers: {
            'Content-Type': 'image/jpeg',
            'Content-Encoding': 'base64',
        }
    });
}

export const createBaseImage = (user, key) => {
    return axios.post(API_URL + '/base_image', { "key": key }, {
        headers: {
            'Authorization': user.token
        }
    });
}

export const deleteBaseImages = (user, pk) => {
    return axios.delete(API_URL + '/base_image/' + pk, {
        headers: {
            'Authorization': user.token
        }
    });
}


export const listBaseImages = (user) => {
    return axios.get(API_URL + '/base_image', {
        headers: {
            'Authorization': user.token
        }
    });
}

export const listCameraEntries = (user) => {
    return axios.get(API_URL + '/camera_entry', {
        headers: {
            'Authorization': user.token
        }
    });
}

export const createCameraEntries = (user, pk, settings, size) => {
	const payload = {
		"pk": pk,
		"settings": settings,
		"size": size
	}
    return axios.post(API_URL + '/camera_entry', payload, {
        headers: {
            'Authorization': user.token
        }
    });
}

export const deleteCameraEntries = (user, pk) => {
    return axios.delete(API_URL + '/camera_entry/' + pk, {
        headers: {
            'Authorization': user.token
        }
    });
}

export const listUsers = (user) => {
    return axios.get(API_URL + '/user', {
        headers: {
            'Authorization': user.token
        }
    });
}

export const listCameraEntriesGallery = (user, identity_id) => {
    return axios.get(API_URL + '/camera_entry_gallery/' + identity_id, {
        headers: {
            'Authorization': user.token
        }
    });
}

export const listAdminCameraEntries = (user) => {
    return axios.get(API_URL + '/camera_entry_admin', {
        headers: {
            'Authorization': user.token
        }
    });
}

export const PRESETS = [
		{
			"value": "24mm lens, fisheye. ISO 100 f1.2 HDR flash, unsettling, cinematic, 8k",
			"text": "Technical preset"
		}, {
			"value": "A dreamlike cityscape in the future in Asia with towering extremely tall skyscraper buildings, bright neon lights, vibrant colors, flying cars, reflective surfaces.",
			"text": "Abstract preset"
		}, {
			"value": "Over the wintry\nforest, winds howl in rage\nwith no leaves to blow.",
			"text": "Poetry preset"
		}
	]