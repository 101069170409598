import { useState } from "react"
import { forgotPassword } from "./auth"
import { Navigate } from "react-router-dom";
import gen8 from '../assets/images/gen8.jpg';

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault()
    setError("")

    try {
      await forgotPassword(email)
      setSuccess(true)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false);
    }
  }

  if (success) {
    return <Navigate to="/reset-password" />
  }

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-around align-items-center">
        <div className="p-4" style={{minWidth: "340px"}}>
          <div className="mb-4">
            <h1 className="h3 mb-3 font-weight-normal">Forgot Password</h1>
            <p>You will receive an email with a code to reset your password</p>
          </div>

          <div className="form-label-group mb-3">
            <label>Email address</label>
            <input type="email" className="form-control" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>

          { !loading && <button className="btn btn-dark btn-block" onClick={handleSubmit} disabled={!emailRegex.test(email)}>Submit</button> }
          { loading && <button className="btn btn-dark btn-block" disabled={true}>
            <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
            Sending Email ... 
          </button> }
          {error && <p>{error}</p>}
        </div>
        <div className="p-4 d-none d-md-block">
          <img src={gen8} className="img-fluid" style={{maxWidth: "500px"}} />
        </div>
      </div>
    </div>
  )
}